import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

const PreviousCompetitionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: .5em 0;
`;

const Title = styled.h2`
    font-size: 1.4em;
    @media screen and (max-width: 980px) {
        font-size: 1.2em;
        text-align: center;
        margin-bottom: 0;
    }
`

const Link = styled.a`
    display: block;
    margin-top: .5em;
    text-decoration: none;
    color: 	#161c2d;
    font-size: .8em;

    @media screen and (max-width: 980px) {
        font-size: .7em;
        text-align: center;
    }
`;

const Copyright = styled.p`
    text: black;
    font-size: .9em;
    text-align: center; 
`

const Footer = styled.footer`
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #abbcd5;
    height: auto;
    padding: 1em;
`;

export default function PreviousCompetitions(props) {
    const data = useStaticQuery(graphql`
        query {
            allContentfulPreviousCompetions {
                nodes {
                  previousCompetitionsTitle
                  previousCompetitions {
                    hasLink
                    link
                    content {
                      content
                      id
                    }
                  }
                }
              }
        }
    `)

    const pageContent = data.allContentfulPreviousCompetions.nodes[0];
    
    return (
        <Footer>
            <Title>{pageContent.previousCompetitionsTitle.toUpperCase()}</Title>
            <PreviousCompetitionsContainer>
                {pageContent.previousCompetitions.map(competition => {
                    if (competition.hasLink) {
                        return <Link key={competition.id} href={competition.link} target="_blank">{competition.content.content}</Link>
                    } else {
                        return <p>{competition.content.content}</p>
                    }
                })}
            </PreviousCompetitionsContainer>
            <Copyright>&copy; CYENS {new Date().getFullYear()}</Copyright>
        </Footer>
    )
}