import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Header } from '../Header';
import OrganisersAndSupportersItem from './OrganisersAndSupportersItem';
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const translateTitle = (props, obj) => {
  return (props.lang === '' || props.lang === 'En-gb') ? obj.title.toUpperCase() : obj.titleGreek.toUpperCase();
}
const OrganisersAndSupportersPageContent = (props) => {
  console.log(props);
  const data = useStaticQuery(graphql`
     query {
        allContentfulOrganisersAndSupporters {
          nodes {
            title
            titleGreek
            entries {
              title
              titleGreek
              media {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `);
  const organisersAndSupportersContent = data.allContentfulOrganisersAndSupporters.nodes[0];
  
  return (
    <Container id="organisers-and-supporters">
      <Header>{translateTitle(props, organisersAndSupportersContent)}</Header>
      <Fragment>
        {organisersAndSupportersContent.entries.map(entry => <OrganisersAndSupportersItem key={entry.id} {...entry} lang={props.lang} />)}
      </Fragment>
    </Container>
  )
}

export default OrganisersAndSupportersPageContent;