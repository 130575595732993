import React from 'react';
import styled from 'styled-components';
import AboutSection from './AboutSection';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 4em;
`;

const AboutSections = ({ sections }) => <Container>{sections.map(section => <AboutSection sectionTitle={section.title} {...section} />)}</Container>;

export default AboutSections;