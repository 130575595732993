import React from 'react';
import Card from '../Card/Card';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';

const Container = styled.div`
    margin-bottom: 4em;
`
const CardListContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const SectionTitle = styled.h1`
    font-size: 2.5em;
    font-size: bolder;
    margin-left: .3em;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
        margin-left: 0;
        font-size: 1.8em;
    }
`;

const SectionDescription = styled.p`
    font-size: .8em;
    margin-left: 1em;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
        margin-left: 0;
        margin-right: 1em;
        text-align: left; 
    }
`
const AboutSection = ({ description, hasDescription, hasCards, sectionTitle, cardList }) => {
    const backgroundValues = ['#e03df6', '#b34df7', '#807ff7', '#5f9fe7', '#82bdff'];
    return (
        <Container>
            <SectionTitle>{sectionTitle.toUpperCase()}</SectionTitle>
            {hasDescription && <SectionDescription>{documentToReactComponents(JSON.parse(description.raw))}</SectionDescription>}
            <CardListContainer>
                {hasCards && cardList.map((card, index) => <Card backgroundColor={backgroundValues[index]} titleHeight="4em" descriptionHeight="19em" titleWidth="11em" descriptionWidth="14.5em" title={card.title} description={card.description.description} />)}
            </CardListContainer>
        </Container>
    )
}

export default AboutSection;