import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Button from '../Button/Button';
import OpeningSectionMobile from './OpeningSectionMobile';

function createMarkup(markup) { return { __html: markup }; }

const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    background: url('//images.ctfassets.net/5zarnsr6dpym/1HEiXBHhBmazjx2au8dwnS/8b4895d76acd9d3c948f4e07cfa9e0bd/homepage-main-image.jpg');
    height: 100vh;
    width: 100%;
    position: relative;
    background-size: cover;
    overflow-y: hidden;
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 980px) {
    top: 0;
  }
`;

const Logo = styled.img`
    width: 30%;
    height: auto;
    object-fit: cover;
    background-cover: cover;
    margin-top: 5em;
    margin-bottom: 1.5em;
    background-size: cover;
    object-fit: cover;
    overflow-y: hidden;
    @media screen and (max-width: 980px) {
      margin-top: .8em;
      width: 60%;
      height: auto;
    }
`;

const OpeningsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1em;
    width: 90%;
    margin-left: 7.5em;
    overflow-y: hidden;
    @media screen and (max-width: 980px) {
      display: none;
    }
`;

const Opening = styled.div`
    width: 33%;
    height: auto;
    overflow-y: hidden;
    text-align: ${props => props.alignment === 'right' ? 'right' : 'left'};
    border-right: ${props => props.index === 0 ? '3px solid white' : 'none'};
`;

const OpeningTitle = styled.h1`
    letter-spacing: 1px;
    font-weight: bold;
    margin: 0;
    color: white;
    font-size: 2em;
`;

const OpeningDate = styled.div`
  color: white;
  font-weight: lighter;
  margin-top: .3em;
  overflow-y: hidden;
`;

const StartEnd = styled.span`
    width: 70%;
    height: auto;
    padding: 1em 1em;
    margin: .7em auto 2.5em auto;
    text-align: center;
    color: white;
    letter-spacing: 1px;
    font-size: 1.5em;
    font-weight: 100;
    overflow-y: hidden;

    @media screen and (max-width: 980px) {
      margin-bottom: 1em;
    }
`;
export default function HomePageContent(props) {
  const data = useStaticQuery(graphql`
      query Home {
        allContentfulHomePageContent {
          nodes {
            title
            startEndDate {
              startEndDate
            }
            openings {
              alignment
              ctaText
              ctaLink
              ctaTextGr
              description {
                description
              }
              descriptionGr
              titleGr
              title
            }
            startEndDateGr {
              startEndDateGr
            }
            backgroundImage {
              file {
                url
              }
            }
            gdcLogo {
              file {
                url
              }
            }
          }
        }
      }
    `);
  const isGreek = props.lang === 'Gr-el';
  const content = data.allContentfulHomePageContent.nodes[0];
  const logo = content.gdcLogo.file.url;
  const competitionStartsEnds = isGreek ? content.startEndDateGr.startEndDateGr : content.startEndDate.startEndDate;
  return (
    <Container>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
      </Helmet>
      <Overlay>
        <Logo src={logo} alt={logo} />
        <StartEnd>{competitionStartsEnds}</StartEnd>
        <OpeningSectionMobile openings={content.openings} isGreek={isGreek} />
        <OpeningsContainer>
          {content.openings.map((opening, i) => {
            return (
              <Opening alignment={opening.alignment} key={i}>
                <OpeningTitle>{isGreek ? opening.titleGr.toUpperCase() : opening.title.toUpperCase()}</OpeningTitle>
                <OpeningDate dangerouslySetInnerHTML={createMarkup(isGreek ? opening.descriptionGr : opening.description.description.toUpperCase())} />
                <Button
                  isClosed={i === 0 ? true : false}
                  hasLink
                  anchorLink={opening.ctaLink}
                  ctaType={opening.ctaType}
                  value={isGreek ? opening.ctaTextGr.toUpperCase() : opening.ctaText.toUpperCase()}
                  marginTop="1.5em" width="40%"
                />
              </Opening>
            )
          })}
        </OpeningsContainer>
      </Overlay>
    </Container>
  )
}