import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Header } from '../Header';

const Container = styled.div`
    margin: 1em 4em;
`;

const Title = styled.h1`
    font-size: 2.5em;
    margin-bottom: .5em;
`;

const Description = styled.p`
    font-size: 1em;
`;

const Image = styled.img`
    width: 100%;
    height: auto;
    margin-bottom: 1em 0;
`;

const FinalEvent = (props) => {
    const data = useStaticQuery(graphql`
    query MyQuery {
        contentfulFinalEvent {
          title
          titleGr
          descriptionEn {
            raw
          }
          descriptionGr {
            raw
          }
          image {
            file {
              url
            }
          }
        }
      }
    `);
    return (
        <div id="final">
            <Header>{props.lang === 'Gr-el' ? data.contentfulFinalEvent.titleGr.toUpperCase() : data.contentfulFinalEvent.title.toUpperCase()}</Header>
            <Container>
                <Title>{props.lang === 'Gr-el' ? data.contentfulFinalEvent.titleGr.toUpperCase() : data.contentfulFinalEvent.title.toUpperCase()}</Title>
                <Description>{props.lang === 'Gr-el' ? documentToReactComponents(JSON.parse(data.contentfulFinalEvent.descriptionGr.raw)) : documentToReactComponents(JSON.parse(data.contentfulFinalEvent.descriptionEn.raw))}</Description>
                <a href="https://my.matterport.com/show/?m=RXYACw57mFb" target="_blank" rel="noreferrer">
                    <Image src={data.contentfulFinalEvent.image.file.url} alt="final" />
                </a>
            </Container>
        </div>
    );
}

export default FinalEvent;