import React from 'react';
import styled from 'styled-components';
import Button from '../Button/Button';
function createMarkup(markup) { return { __html: markup }; }

const OpeningsContainer = styled.div`
    display: none;
    overflow-y: hidden;
    @media screen and (max-width: 980px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        padding: 1em;
    }
`;
const Opening = styled.div`
    width: 100%;

    @media screen and (max-width: 980px) {
        text-align: center;
    }
`;

const OpeningTitle = styled.h1`
    font-size: 1.2em;
`;

const OpeningDate = styled.div`
    color: white;
    font-weight: lighter;
    margin-top: .3em;
`;


const OpeningSectionMobile = ({ isGreek, openings }) => {
    const renderEnrolCta = opening => <Button hasLink isClosed={true} anchorLink="https://forms.office.com/Pages/ResponsePage.aspx?id=4XAKFKCr0U6-HQTBCX_8jDY75HIXDPlNsczsUVKTz5xUNlcxMTNNMThGU1g1Uko4Nlk5WldKN09OMC4u" ctaType={opening.ctaType} value={isGreek ? opening.ctaTextGr.toUpperCase() : opening.ctaText.toUpperCase()} marginTop="1.5em" width="80%" />;
    return (
        <OpeningsContainer>
            {openings.map((opening, i) => {
                return (
                    <Opening key={i}>
                        <OpeningTitle>{isGreek ? opening.titleGr.toUpperCase() : opening.title.toUpperCase()}</OpeningTitle>
                        <OpeningDate dangerouslySetInnerHTML={createMarkup(isGreek ? opening.descriptionGr : opening.description.description.toUpperCase())} />
                        {i === 0 ? renderEnrolCta(opening) : <Button ctaType={opening.ctaType} value={isGreek ? opening.ctaTextGr.toUpperCase() : opening.ctaText.toUpperCase()} marginTop="1.5em" width="80%" />}
                        <hr />
                    </Opening>
                )
            })}
        </OpeningsContainer>
    );
}

export default OpeningSectionMobile;