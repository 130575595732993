import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Header } from '../Header';
import AboutSections from './AboutSections';
import about from '../../translations/about_gr.json';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './styles.css';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    background-color: #101010;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
    }
`;

const LeftImageContainer = styled.div`
    width: 48%;
    height: 100vh;
    margin-right: .5em;
    overflow-y: hidden;
    object-fit: cover;
    background-cover: cover;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
      width: 100%;
      height: 30vh;
      margin-left: 1em;
    }
`;

const Banner = styled.img`
    width: 100%;
    height: 100vh;
    margin-top: 5em;
    object-fit: cover;
    background-cover: cover;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
      height: 30vh;
      width: 90%;
      margin-top: 1em;
    }
`

const DescriptionContainer = styled.div`
    width: 48%;
    margin-left: .5em;
    display: flex;
    flex-direction: column;
    font-size: 1em;
    height: auto;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
      width: 90%;
    }
`;

const ItsNoGameImage = styled.img`
    width: 100%;
    height: auto;
    margin-top: 1em;
    @media screen and (max-width: 980px) {
      width: 60%;
    }
`;

const Description = styled.p`
  text-align: left;
  line-height: 1.6;
  margin: 1.5em 0;
  height: auto;
  font-size: 1.1em;
  color: #d8d8d8 !important;
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    margin-top: .8em;
    font-size: .8em;
  }
`;

const LinesImage = styled.img`
  height: 13em;

  @media screen and (min-width: 320px) and (max-width: 1024px) {
    height: 7em;
  }
`;

const AboutSectionsContainerWithLines = styled.div`
  display: flex;
  flex-direction: row;
`;

const About = (props) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulAboutPageContent {
                nodes {
                  aboutPageImageBanner {
                    file {
                      url
                    }
                  }
                  descriptionEn {
                    raw
                  }
                  itsNoGameImage {
                    file {
                      url
                    }
                  }
                  rightLinesImage {
                    file {
                      url
                    }
                  }
                  sections {
                    hasDescription
                    hasCards
                    title
                    description {
                        raw
                    }
                    cardList {
                      description {
                        description
                      }
                      title
                    }
                  }
                  bottomRightImage {
                    file {
                      url
                    }
                  }
                }
              }
        }
    `);
    
    const aboutPageData = (props.lang === '' || props.lang === 'En-gb') ? data.allContentfulAboutPageContent.nodes[0] : about.allContentfulAboutPageContent.nodes[0]
    const banner = aboutPageData.aboutPageImageBanner.file.url;
    const itsnogame = aboutPageData.itsNoGameImage.file.url;
    const descriptionText = documentToReactComponents(JSON.parse(aboutPageData.descriptionEn.raw));
    const headerText = (props.lang === '' || props.lang === 'En-gb') ? 'ABOUT' : 'ΓΙΑ ΤΟΝ ΔΙΑΓΩΝΙΣΜΟ';
    return (
        <div id="about">
            <Container>
                <LeftImageContainer>
                    <Banner src={banner} alt="banner" />
                </LeftImageContainer>
                <DescriptionContainer className="descriptionContainer">
                    <ItsNoGameImage src={itsnogame} alt="itsnogame" />
                    <Description style={{ color: '#d8d8d8 !important' }}>{descriptionText}</Description>
                </DescriptionContainer>
            </Container>
            <Header>{headerText}</Header>
            <AboutSectionsContainerWithLines>
                <LinesImage src={aboutPageData.rightLinesImage.file.url} />
                <AboutSections sections={aboutPageData.sections} />
            </AboutSectionsContainerWithLines>
        </div>
    )
};

export default About;