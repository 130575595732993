import { Link } from 'gatsby';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container, Nav, Navbar } from "react-bootstrap";
import styled from 'styled-components';
import SelectLanguage from '../../containers/SelectLanguage';

import "bootstrap/dist/css/bootstrap.min.css";
import './navigationBar.css';

const ListItem = styled(Link)`
    display: block;
    text-decoration: none;
    color: #f7f7f7;
    font-size: .8em;
    margin: 1em 1.5em 0 0;
    letter-spacing: 0.09em;
    &:hover {
        color: #f7f7f7;
        text-decoration: underline;
    }
`;

const LinkToWeb = styled.a`
    display: block;
    text-decoration: none;
    color: #f7f7f7;
    font-size: .8em;
    margin: 1em 1.5em 0 0;
    letter-spacing: 0.09em;
    &:hover {
        color: #f7f7f7;
        text-decoration: underline;
    }
`;

const NavigationBar = (props) => {
    const data = useStaticQuery(graphql`
            query {
                allContentfulNavigationBar {
                    nodes {
                        items {
                            id
                            slug
                            text
                            textGreek
                        }
                    }
                }
            }
        `);
    const isEnglish = props.lang === '' || props.lang === 'En-gb';
    return (
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top" style={{ justifyContent: 'flex-start!important' }}>
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ color: "white" }}/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {data.allContentfulNavigationBar.nodes[0].items.map(item => {
                            const { slug, text, textGreek } = item;
                            if (slug === 'about' || slug === "organisers-and-supporters" || slug === 'contact'|| slug === 'final') {
                                return <LinkToWeb href={`#${slug}`}>{isEnglish ? text.toUpperCase() : textGreek.toUpperCase()}</LinkToWeb>;
                            } else if (slug === 'enrol') {
                                return <LinkToWeb href="https://forms.office.com/Pages/ResponsePage.aspx?id=4XAKFKCr0U6-HQTBCX_8jDY75HIXDPlNsczsUVKTz5xUNlcxMTNNMThGU1g1Uko4Nlk5WldKN09OMC4u" target="_blank">{isEnglish ? item.text.toUpperCase() : item.textGreek.toUpperCase()}</LinkToWeb>
                            } else if (slug === 'rules') {
                                return <ListItem to={`/regulations`}>{isEnglish ? text.toUpperCase() : textGreek.toUpperCase()}</ListItem>;
                            } else if (slug === 'organising-committee' || slug === 'news') {
                                return <ListItem to={`/${slug}/`}>{isEnglish ? text.toUpperCase() : textGreek.toUpperCase()}</ListItem>
                            }
                            return <LinkToWeb href="#home">{isEnglish ? text.toUpperCase() : textGreek.toUpperCase()}</LinkToWeb>
                        })}
                        <SelectLanguage />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavigationBar;