import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Header } from '../Header';
import Mentor from './Mentor';

const Container = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
    }
`;

const Mentors = ({ lang }) => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulMentorPageContent {
          nodes {
            title
            mentors {
              description {
                description
              }
              descriptionGr {
                descriptionGr
              }
              name
              mentorImage {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `);

    return (
        <Fragment>
            <Header>{data.allContentfulMentorPageContent.nodes[0].title.toUpperCase()}</Header>
            <Container>
                {data.allContentfulMentorPageContent.nodes[0].mentors.map(mentor => <Mentor lang={lang} key={mentor.title} {...mentor} />)}
            </Container>
        </Fragment>
    );
}

export default Mentors;