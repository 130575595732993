import React, { Fragment } from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
    text-align: center;
    margin: 1.5em 0;

    @media screen and (max-width: 980px) {
        display: flex;
        flex-direction: column;
    }
`;

const Logo = styled.img`
    width: 20%;
    height: auto;
    background-cover: cover;
    margin: 0 auto;
    margin-right: 2.5em;

    @media screen and (max-width: 980px) {
        width: 45%;
        height: auto;
        margin: -1em auto 0 auto;
    }
`;

const Title = styled.h1`
    font-size: 2.5em;
    color: black;
    text-align: center;
    margin-bottom: .1em;
    margin-top: 1.5em;

    @media screen and (max-width: 980px) {
        font-size: 1.5em;
    }
`;

const HorizontalLine = styled.hr`
    margin: 0 10em;
    @media screen and (max-width: 980px) {
        margin: 0 3em;
        height: 3px;
    }
`;

const OrganisersAndSupportersItem = props => {
    return (
        <Fragment>
            <Title>{(props.lang === '' || props.lang === 'En-gb') ? props.title.toUpperCase() : props.titleGreek.toUpperCase()}</Title>
            <HorizontalLine />
            <LogoContainer>
                {props.media.map(organiser => <Logo src={organiser.file.url} alt="organiser" />)}
            </LogoContainer>
        </Fragment>
    )
};

export default OrganisersAndSupportersItem;