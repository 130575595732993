import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    margin: 1.5em 3em;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
        width: 75%;
    }
`;

const MentorImage = styled.img`
    width: 200px;
    height: 200px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    border-radius: 50%;
    margin-bottom: 1em;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
        width: 128px;
        height: 128px;
    }
`;

const MentorName = styled.h4`
    font-size: 1.5em;
    color: #343a40;
    margin: 0 auto;
    font-weight: bold;
    text-align: center;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
        font-size: 1em;
    }
`;

const MentorDescription = styled.p`
    font-size: 1em;
    font-weight: lighter;
    color: #343a40;
    margin-top: 1em;
    text-align: justify;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
        font-size: .8em;
    }
`;

const Mentor = ({ description, mentorImage, name, }) => {
    return (
        <Container>
            <MentorImage src={mentorImage.file.url} alt={name} />
            <MentorName>{name}</MentorName>
            {!description ? null : <MentorDescription>{description.description}</MentorDescription>}
        </Container>
    );
};

export default Mentor;