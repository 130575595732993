import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
        display: flex;
        flex-direction: column;       
    }
`;

const LanguageCTA = styled.button`
    width: 50%;
    background: linear-gradient(90deg, rgba(224,61,246,1) 0%, rgba(128,127,247,1) 35%, rgba(130,189,255,1) 100%);
    border: none;
    padding: .8em 2em;
    margin-right: 1em;
    color: #f7f7f7;
    cursor: pointer;
    text-align: center;
    font-size: .8em;
    border-radius: 20px;
    letter-spacing: 0.09em;

    @media screen and (min-width: 320px) and (max-width: 1024px) {
        width: 40%; 
        margin-top: 1em;       
    }
`;

export default function SelectLanguage({ selectLanguage }) {
    return (
        <Container>
            <LanguageCTA value="En-gb" onClick={e => selectLanguage(e.target.value)}>EN</LanguageCTA>
            <LanguageCTA value="Gr-el" onClick={e => selectLanguage(e.target.value)}>GR</LanguageCTA>
        </Container>
    )
}