import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: .5em 1em;
`;

const Title = styled.div`
    overflow-y: hidden;
    text-align: left;
    color: white;
    font-weight: bolder;
    height: ${props => props.titleHeight};
    padding: .5em 2em 1em .5em;
    width: ${props => props.titleWidth};
    background-color: ${props => props.backgroundColor};
    font-size: 1.2em;

    @media screen and (min-width: 320px) and (max-width: 1024px) {
        height: 4.5em;
        width: 9em;
        font-size: 1em;
    }
`;

const Description = styled.div`
    background-color: black;
    color: #d8d8d8;
    padding: 1em 1em 2em 1em;
    width: ${props => props.descriptionWidth};
    height: ${props => props.descriptionHeight};
    font-size: .9em;
    overflow-y: hidden;

    @media screen and (min-width: 320px) and (max-width: 1024px) {
        height: 20.5em;
        width: 10em;
        scroll-y: scroll
    }
`;

const Card = ({ backgroundColor, descriptionWidth, descriptionHeight, title, titleHeight, titleWidth, description }) => {
    return (
        <Container>
           <Title
            backgroundColor={backgroundColor}
            titleHeight={titleHeight}
            titleWidth={titleWidth}
           >
             {title}
            </Title>
           <Description
            descriptionHeight={descriptionHeight}
            descriptionWidth={descriptionWidth}
           >
            {description}
           </Description> 
        </Container>
    )
}

export default Card;