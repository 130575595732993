import * as React from "react";
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import HomePage from "../containers/HomePage";
import Navigation from '../containers/Navigation';
import AboutUs from "../containers/AboutUs";
import PreviousCompetitions from "../components/PreviousCompetitions/PreviousCompetitions";
import './index.css';
import OrganisersAndSupporters from "../containers/OrganisersAndSupporters";
import Contact from "../components/Contact/Contact";
import Mentors from "../containers/Mentors";
import FinalEvent from "../containers/FinalEvent";
const Container = styled.div`
  font-family: 'Roboto', sans-serif;
`;

const IndexPage = () => {
  return (
    <Container>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
      </Helmet>
      <Navigation />
      <div>
        <HomePage />
        <AboutUs />
        <Mentors />
        <FinalEvent />
        <OrganisersAndSupporters />
        <Contact />
        <PreviousCompetitions />
      </div>
    </Container>
  )
}

export default IndexPage
