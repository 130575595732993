import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { connect } from 'react-redux';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Header } from '../Header';

const Container = styled.div`
    
`;

const ContentContainer = styled.div`
    padding: 1em;
`

const Contact = props => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulContact {
                nodes {
                  information {
                    raw
                  }
                  informationGr {
                    raw
                  }
                  title
                }
              }
        }
    `)

    const pageContent = data.allContentfulContact.nodes[0];
    const displayedInformation = (props.lang === '' || props.lang === 'En-gb') ? documentToReactComponents(JSON.parse(pageContent.information.raw)) : documentToReactComponents(JSON.parse(pageContent.informationGr.raw));
    return (
        <Container id="contact">
            <Header>{(props.lang === '' || props.lang === 'En-gb') ? pageContent.title.toUpperCase() : 'ΕΠΙΚΟΙΝΩΝΙΑ'}</Header>
            <ContentContainer>{displayedInformation}</ContentContainer>
        </Container>
    )
};

const mapStateToProps = ({ language }) => ({ lang: language.lang });

export default connect(mapStateToProps)(Contact);